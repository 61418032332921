import { ReactNode } from 'react';

import { Widget, WidgetBody } from '@/portainer/components/widget';

interface Props {
  value?: number;
  icon: string;
  comment: string;
  children?: ReactNode;
}

export function DashboardItem({ value, icon, comment, children }: Props) {
  return (
    <div className="col-sm-12 col-md-6" aria-label={comment}>
      <Widget>
        <WidgetBody>
          <div className="widget-icon blue pull-left">
            <i className={icon} aria-hidden="true" aria-label="icon" />
          </div>
          <div className="pull-right">{children}</div>
          <div className="title" aria-label="value">
            {typeof value !== 'undefined' ? value : '-'}
          </div>
          <div className="comment" aria-label="resourceType">
            {comment}
          </div>
        </WidgetBody>
      </Widget>
    </div>
  );
}
