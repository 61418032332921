const autoNgTemplateLoaderTemplate1 = require('./wizard-endpoints.html');

import angular from 'angular';
import controller from './wizard-endpoints.controller.js';
import './wizard-endpoints.css';

import './WizardNomad';
import './wizard-endpoint-list';

angular.module('portainer.app').component('wizardEndpoints', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
});
