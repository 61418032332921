import { ResourceControlOwnership } from 'Portainer/models/resourceControl/resourceControlOwnership';

import { AccessControlFormData } from '../components/accessControlForm/model';
import { TeamId } from '../teams/types';
import { UserId } from '../users/types';

import { OwnershipParameters } from './types';

/**
 * Transform AccessControlFormData to ResourceControlOwnershipParameters
 * @param {int} userId ID of user performing the operation
 * @param {AccessControlFormData} formValues Form data (generated by AccessControlForm)
 * @param {int[]} subResources Sub Resources restricted by the ResourceControl
 */
export function parseOwnershipParameters(
  userId: UserId,
  formValues: AccessControlFormData,
  subResources: (number | string)[] = []
): OwnershipParameters {
  let { ownership } = formValues;
  if (!formValues.accessControlEnabled) {
    ownership = ResourceControlOwnership.PUBLIC;
  }

  let adminOnly = false;
  let publicOnly = false;
  let users: UserId[] = [];
  let teams: TeamId[] = [];
  switch (ownership) {
    case ResourceControlOwnership.PUBLIC:
      publicOnly = true;
      break;
    case ResourceControlOwnership.PRIVATE:
      users.push(userId);
      break;
    case ResourceControlOwnership.RESTRICTED:
      users = formValues.authorizedUsers;
      teams = formValues.authorizedTeams;
      break;
    default:
      adminOnly = true;
      break;
  }
  return {
    administratorsOnly: adminOnly,
    public: publicOnly,
    users,
    teams,
    subResources,
  };
}
