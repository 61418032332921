const autoNgTemplateLoaderTemplate1 = require('./endpointsDatatable.html');

import './endpoints-datatable.css';

angular.module('portainer.app').component('endpointsDatatable', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'EndpointsDatatableController',
  bindings: {
    titleText: '@',
    titleIcon: '@',
    tableKey: '@',
    orderBy: '@',
    reverseOrder: '<',
    removeAction: '<',
    retrievePage: '<',
  },
});
