import { ReactNode } from 'react';

interface Option {
  label: string | ReactNode;
  children: ReactNode;
  id: string | number;
}

interface Props {
  options: Option[];
  selectedId: string | number;
  onSelect?(id: string | number): void;
}

export function NavTabs({ options, selectedId, onSelect = () => {} }: Props) {
  const selected = options.find((option) => option.id === selectedId);

  return (
    <>
      <ul className="nav nav-tabs">
        {options.map((option) => (
          <li className="nav-item active" key={option.id}>
            {/* rule disabled because `nav-tabs` requires an anchor */}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */}
            <a
              className="nav-link"
              onClick={() => onSelect(option.id)}
              role="button"
              tabIndex={0}
            >
              {option.label}
            </a>
          </li>
        ))}
      </ul>
      {selected && <div className="tab-content">{selected.children}</div>}
    </>
  );
}
